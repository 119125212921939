import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router';
import { useAuthContext } from 'src/contexts/AuthContext';
import { Container, Button, Form, Input, Img } from './styles';
import { useForgotPassword } from './useForgotPassword';

interface UserLoginProps {
  email: string;
}
type property = 'email' | 'password';

export default function ForgotPasswordPage() {
  const [user, setUser] = useState<UserLoginProps>();
  const { signIn, isAuthenticated } = useAuthContext();
  const { forgotPassword } = useForgotPassword();
  const [hasSent, setHasSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async () => {
    try {
      setHasSent(false);
      setIsLoading(true);
      await forgotPassword(user.email);
      setUser({ email: '' });
      setHasSent(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (property: property, value: string) => {
    const data = {
      ...user,
      [property]: value
    };
    setUser(data);
  };

  useEffect(() => {
    isLogged();
  }, [isAuthenticated]);

  const isLogged = () =>
    isAuthenticated === true && <Navigate to="validation" />;

  return (
    <>
      <Helmet>
        <title>backoffice</title>
      </Helmet>

      <Container>
        <Form>
          <Img src="/static/images/logo/ambev-logo-s.png" alt="Logo" />
          {hasSent && (
            <Alert color="success">
              Foi enviado link de redefinição para o seu e-mail
            </Alert>
          )}
          <Input
            label="E-mail"
            type="email"
            value={user?.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
          <a href="/login">Voltar para login</a>
          <Button disabled={isLoading} onClick={handleForgotPassword}>
            {
              isLoading
                ? 'Enviando...'
                : 'Enviar'
            }
             </Button>
        </Form>
      </Container>
    </>
  );
}
