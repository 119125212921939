import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthContextProvider } from './contexts/AuthContext';
import { ValidationCnhContextProvider } from './contexts/validation/ValidationContext';
import './global.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


ReactDOM.render(
  <HelmetProvider>
     <AuthContextProvider>  
      <SidebarProvider>
        <BrowserRouter>
        <ValidationCnhContextProvider>
          <ToastContainer/>
          <App />
        </ValidationCnhContextProvider>
        </BrowserRouter>
      </SidebarProvider>
    </AuthContextProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
