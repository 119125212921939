import { toastMessage } from "src/components/ToastMessege"
import api from "src/services/api"


export function useForgotPassword() {

    async function forgotPassword(email: string) {
        try {
            const {data} = await api.post('/gupy/test/forgot-password',{ email })

            toastMessage({
                type: 'success',
                title: 'E-mail enviado com sucesso!'
            }) 

        } catch (error) {
           toastMessage({
               type: 'error',
               title: 'Ocorreu um erro enviar reset de senha'
           }) 

           throw new Error(error)
        }   
    }

    return {
        forgotPassword
    }
}