import React from 'react'
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import api from "../services/api";
import { recoverUserInformation } from "../services/auth";

type User = {
  id: string;
  name: string;
  email: string;
  role: string;
  picture: string
} | null

type SignInData = {
  email: string;
  password: string;
}

type AuthContextType = {
  isAuthenticated: boolean;
  user: User;
  signIn: (data: SignInData) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
  isReaderUser: () => boolean;
  isInvalidCrendentials: boolean
}


export const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsloading] = useState(true)
  const [isInvalidCrendentials, setIsInvalidCredentials] = useState(false)
  const isAuthenticated = !!user || false;

  async function signIn({ email, password }: SignInData) {
    setIsInvalidCredentials(false)
    try {
      const {data} = await api.post('/user/login',{email, password})    
      const {token, user} = data.payload
      
      if(token){
        localStorage.setItem('authorization', token)
        api.defaults.headers.common['Authorization'] = token
        setUser(user)
      }
    } catch (error) {
      setIsInvalidCredentials(true)
    }
  }  

  function logout(){
    localStorage.removeItem('authorization')
    setIsloading(false)
     setUser(null)
  }

  async function verifyUserLogged(){
    setIsloading(true)
    const token = localStorage.getItem('authorization')
    const userLogged = await recoverUserInformation(token || '')
    if(!userLogged){
      localStorage.removeItem('authorization')
      setIsloading(false)
      setUser(null)
      return false
    }
    setUser(userLogged)   
    setIsloading(false)   
    return true
  }

  function isReaderUser(){
    return user.role === 'RECRUITER_READER'
  }


  useEffect(()=>{
    setIsloading(true)
    verifyUserLogged()
},[])
  
 
  return (
    
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        signIn,
        isLoading,
        logout,
        isReaderUser,
        isInvalidCrendentials
      }}
    >
      {!isLoading && children}
      
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
