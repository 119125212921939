import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router';
import { useAuthContext } from 'src/contexts/AuthContext';
import { Container, Button, Form, Input, Img } from './styles';

interface UserLoginProps {
  email: string;
  password: string;
}
type property = 'email' | 'password';

export default function LoginPage() {
  const [user, setUser] = useState<UserLoginProps>();
  const { signIn, isAuthenticated, isInvalidCrendentials } = useAuthContext();

  const handleSigIn = async () => {
    signIn(user);
  };

  const handleChange = (property: property, value: string) => {
    const data = {
      ...user,
      [property]: value
    };
    setUser(data);
  };

  useEffect(() => {
    isLogged();
  }, [isAuthenticated]);

  const isLogged = () =>
    isAuthenticated === true && <Navigate to="validation" />;

  return (
    <>
      <Helmet>
        <title>backoffice - login</title>
      </Helmet>

      <Container>
        <Form>
          
          <Img
            src="/static/images/logo/ambev-logo-s.png"
            alt="Logo"
          />
          {
            isInvalidCrendentials && (
              <Alert color='error'>Email ou senha inválidos</Alert>
            )
          }
          <Input
            label="E-mail"
            type="email"
            value={user?.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
          <Input
            label="Senha"
            type="password"
            value={user?.password}
            onChange={(e) => handleChange('password', e.target.value)}
          />
          <a href="/forgot-password">Redefinir senha</a>
          <Button onClick={handleSigIn}>Entrar</Button>
        </Form>
      </Container>
    </>
  );
}
