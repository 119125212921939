import {filter, forEach, map, merge, mergeWith, some} from 'lodash'
import {
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  TextareaAutosize,
  TextField
} from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { Alert } from '../styles';
import useReviewCNH from '../useCandidateReview';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TextArea } from 'src/components/TextArea';
import { useAuthContext } from 'src/contexts/AuthContext';

const contextModalMesseges = {
  aprove: {
    icon: <ThumbUpOutlinedIcon />,
    title: 'Aprovar',
    messege:
      'Aprovando este relatório, você declara estar ciente da situação atual da pessoa e assume total reponsabilidade por esta ação',
    textButton: 'Sim, marcar como valido',
    variantColor: 'green'
  },
  reprove: {
    icon: <ThumbUpOutlinedIcon />,
    title: 'Reprovar',
    messege:
      'Reprovando este relatório, você declara estar ciente da situação atual da pessoa e assume total reponsabilidade por esta ação',
    textButton: 'Sim, marcar como invalido',
    variantColor: 'red'
  }
};

export enum contexModalEnum {
  aprove = 'aprove',
  reprove = 'reprove'
}

interface ModalChangeReportProps {
  validationId: string;
  showModal: boolean;
  contextModal: contexModalEnum;
  onToggle: () => void;
  namePerson?: string;
  documentPerson?: string;
  reportid?: string,
  checksToValidate?: {
    less_than_eight_points?: boolean | null,
    no_serious_penalty?: boolean | null,
    not_expired?: boolean | null,
    is_definitive?: boolean | null,
    isCorrectCategory?: boolean | null
  }
}

interface validationCheckProps {
  is_definitive?: boolean;
  less_than_eight_points?: boolean;
  no_serious_penalty?: boolean;
  not_expired?: boolean;
  isCorrectCategory?: boolean;
}

export function ModalValidate({
  validationId,
  showModal,
  contextModal,
  onToggle,
  namePerson,
  documentPerson,
  checksToValidate,
  reportid
}: ModalChangeReportProps) {
  const { sendValidationReport } = useReviewCNH();
  const {user} = useAuthContext()
  const [messegeReview, setMessegeReview] = useState(`Validado manualmente por: ${user?.name} \n`);
  const [validationCheck, setValidationCheck] =
    useState<validationCheckProps | null>();
  const navigation = useNavigate();

  const handleChange = (value: string) => {
    setMessegeReview(value?.trim());
  };

  const handleSendValidation = async () => {
    const validationContext =
      contextModal === contexModalEnum.aprove ? true : false;
    await sendValidationReport(validationId, {
      aprovar: contextModal === contexModalEnum.aprove,
      mensagem: messegeReview,
      ...(reportid && {reportid}),
      ...(validationCheck && {
        dataValidationCheck: {
          ...(validationCheck?.less_than_eight_points && {
            less_than_eight_points: validationContext
          }),
          ...(validationCheck?.is_definitive && {
            is_definitive: validationContext
          }),
          ...(validationCheck?.no_serious_penalty && {
            no_serious_penalty: validationContext
          }),
          ...(validationCheck?.not_expired && {
            not_expired: validationContext
          }),
          ...(validationCheck?.isCorrectCategory && {
            isCorrectCategory: validationContext
          }),
          ...(validationContext === false && {
            disaprovalReason: messegeReview
          })
        }
      })
    });

    onToggle();
    setMessegeReview('');
    navigation('/validation');
  };

  const handleCheckValidation = (
    value: string,
    checkState: string,
    checked: boolean
  ) => {
    if (checked) {
      setMessegeReview(messegeReview + `${value}; `);
      return setValidationCheck((prevState) => ({
        ...prevState,
        [checkState]: checked
      }));
    }
    const newMessege = messegeReview.replace(`${value};`, '');
    setMessegeReview(newMessege);
    setValidationCheck((prevState) => ({
      ...prevState,
      [checkState]: checked
    }));
  };

  const checkToAprove = [
    {
      value: 'Menor ou igual a 8 ponto',
      state: true,
      stateLabel: 'less_than_eight_points'
    },
    {
      value: 'Sem multa gravíssima',
      state: true,
      stateLabel: 'no_serious_penalty'
    },
    {
      value: 'CNH não expirada',
      state: true,
      stateLabel: 'not_expired'
    },
    {
      value: 'CNH definitiva',
      state: true,
      stateLabel: 'is_definitive'
    },
    {
      value: 'CNH compatível',
      state: true,
      stateLabel: 'isCorrectCategory'
    }
  ];

  const allCheckToReprove = [
    {
      value: 'Acima de 8 pontos',
      state: validationCheck?.less_than_eight_points || false,
      stateLabel: 'less_than_eight_points'
    },
    {
      value: 'Multa gravíssima',
      state: validationCheck?.no_serious_penalty || false,
      stateLabel: 'no_serious_penalty'
    },
    {
      value: 'CNH expirada',
      state: validationCheck?.not_expired || false,
      stateLabel: 'not_expired'
    },
    {
      value: 'CNH não definitiva',
      state: validationCheck?.is_definitive || false,
      stateLabel: 'is_definitive'
    },
    {
      value: 'CNH não compatível',
      state: validationCheck?.isCorrectCategory || false,
      stateLabel: 'isCorrectCategory'
    }
  ];

  const checkToReprove = map(checksToValidate, (item, key)=>{
    return filter(allCheckToReprove, (check)=> check.stateLabel === key && item === null)
  }).flat()

  const isOptionSelected = some(validationCheck, (validation)=> validation)

  useEffect(()=>{
    setMessegeReview(`Validado manualmente por: ${user?.name} \n`)
    setValidationCheck(null)
  },[checksToValidate])

  return (
    <Modal
      open={showModal}
      onClose={onToggle}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 550,
          bgcolor: 'background.paper',
          //  border: '2px solid #000',
          borderRadius: 1,
          boxShadow: 24
          //  pt: 2,
          //  px: 4,
          //  pb: 3,
        }}
      >
        <CardHeader title={contextModalMesseges[contextModal].title} />
        <hr />
        <Alert severity="warning">
          <AlertTitle>Declaração</AlertTitle>
          <p>{contextModalMesseges[contextModal].messege}</p>
          <span>
            <b>Nome</b>: {namePerson} - {documentPerson}
          </span>
        </Alert>
        <Box
          sx={{
            margin: 2
          }}
        >
          <FormGroup className="mx-10">
            {(contextModal === 'reprove' ? checkToReprove : checkToAprove).map(
              (check, index) => (
                <FormControlLabel
                  key={index + 1}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        handleCheckValidation(
                          check.value,
                          check.stateLabel,
                          e.target.checked
                        )
                      }
                      checked={check.state}
                      disabled={contextModal === contexModalEnum.aprove}
                    />
                  }
                  label={check.value}
                />
              )
            )}
          </FormGroup>
          <TextArea 
          placeholder='Mensagem de validação, no minimo 20 caracteres'
          value={messegeReview} onChange={(e)=> handleChange(e.target.value)}/>
          <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end'
                }}
            >
                <Button variant='outlined'
                    color='inherit'
                    sx={{
                        marginRight: 2
                    }}
                    onClick={()=> onToggle()}
                    >Cancelar</Button>

                <Button variant='contained'
                    // disabled={contextModal === contexModalEnum.reprove && !isOptionSelected}
                    color={contextModal === contexModalEnum.aprove ? 'success' : 'error'}
               
               onClick={()=> handleSendValidation()}
               >{contextModal === contexModalEnum.aprove ? 'Aprovar' : 'Reprovar'}</Button>
            </Box>
                  
        </Box>
      </Card>
    </Modal>
  );
}
