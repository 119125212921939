import styled from "@emotion/styled";
import { Check as CheckWrapper  } from "@mui/icons-material";

import { 
    Container as ContainerWrapper, 
    Card as CardWrapper, 
    Alert as AlertWrapper,
    ButtonBase as ButtonWrapper
} from "@mui/material";
import { Link as LinkWrapper } from "react-router-dom";
// import {Chec} from '@mui/material/Icon'

export const Container = styled(ContainerWrapper)`
  overflow: auto;
  flex: 1;
  overflow-x: hidden;
  align-items: center;
  margin-top: 15px;
  
`

export const Card = styled(CardWrapper)`
    padding: 10px;
    margin: 10px 0;
`

export const Alert = styled(AlertWrapper)`

`
interface contextButton {
    context: 'approve' | 'reprove' 
} 
const colorsButton = {
    approve: '#22C55E',
    reprove: '#EF4444'
}
export const Button = styled(ButtonWrapper)`
    background: ${(props:contextButton) => colorsButton[props.context]} ;
    padding: 0.7rem;
    margin: 5px;
    color: white;
    font-weight: 600;
   
    &:hover {
        filter: brightness(85%);
    }
    
`

export const Ul = styled.ul`
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    span {
        font-size: 1rem;
        font-weight: 500;
    }
`

const setVarianteColorCheck = (status:boolean | null)=>{
        switch (status) {
            case true:
                return '#008000'
            
            case false:
                return '#FF0000'
            default:
                return '#FCBE11'
        }
    
}

type PropsCheck = {
    variant: true | false | null
}

export const Check = styled(CheckWrapper)`
    background: ${(props: PropsCheck) => setVarianteColorCheck(props.variant)};
    color: white;
    border-radius: 100%;
    margin-right: 8px;
`

interface LinkProps {
    border?:boolean
}

export const Link = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    color: black;
    padding: 4px;
    border-radius: 5px;
    text-decoration: none;

    border: ${({border}:LinkProps)=> border ? ' 1px solid green' : 'none'};

     &:hover {
            background: #E2E8F0;
        }
`

export const BodyLi = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 7px;
    
`

export const ImgLogo = styled.img`
    height: 30px;
`

export const ImgDoc = styled.img`
    height: 60px;
    margin: 10px
`