import { useState } from 'react';
import { toastMessage } from '../../../components/ToastMessege';
import api from '../../../services/api';

type ReportDataType = {
  cnhValidationData: {
    pontuation: PenaltyProps[]
    id: string;
    reportid: string,
    createdAt: string;
    category: string;
    city: string;
    uf: string;
    cnh_front: string;
    cnh_back: string;
    cnh_digital: string;
    nadaconsta: string;
    cnhnumber: string;
    cpf: string;
    docemissionplace: string;
    doctype: string;
    expeditiondate: string;
    firstqualificationdate: string;
    identificationnumber: string;
    validdate: string;
    not_expired: boolean;
    is_definitive: boolean;
    no_serious_penalty: boolean;
    less_than_eight_points: boolean;
    isCorrectCategory: boolean;
    status: string;
    isAutomatic: boolean | null;
    isInvalidFirstStep: boolean | null;
    invalidFirstStepDescription: string;
    disaprovalReason: string | null;
    application_id: string;
    isApproved: boolean;
    user: {
      name: string;
    };
    gupy_test_candidate: {
      candidateId: string;
      name: string;
      gender: string;
      birthdate: string;
      company_id: number;
      job_id: number;
      jobName: string;
      jobCode: string;
      recruiter: string;
      phone: string,
      gupy_test: {
        category: string;
      };
    };
  };
}

export type PenaltyProps = {
    dateTime: string;
    emitido_em: string;
    place: string
    local: string;
    points: string;
    pontos: string;
    organ: string;
    fonte: string;
    hora: string
    infraction: string;
    descricao: string;
} 

interface paramSendValidationProps {
  aprovar: boolean;
  mensagem: string;
}

const useReviewCNH = () => {
  const [reportData, setReportData] = useState<ReportDataType>();
  const [penalties, setPenalties] = useState<PenaltyProps[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPenalties, setIsLoadingPenalties] = useState(true)

  const getReportDetails = async (validationId: string) => {
    const { data } = await api.get(
      `gupy/test/cnh-validation/reports/${validationId}/report-details`
    );

    setReportData(data.payload as ReportDataType);
    setIsLoading(false)
  };

  const getPenailtiesCandidate = async (reportid: string) => {
    const { data } = await api.get(
      `gupy/test/cnh-validation/reports/${reportid}/penalties`
    )
    setPenalties(data.payload)
    setIsLoadingPenalties(false)
  }

  const sendValidationReport = async (
    reportId: string,
    validationData: paramSendValidationProps
  ) => {
    try {
      const { data, status } = await api.post(
        `gupy/test/cnh-validation/reports/${reportId}/validate-manual`,
        validationData
      );
      if (status === 200) {
        toastMessage({
          type: 'success',
          title: 'Relatorio validado'
        });
        return data;
      }
    } catch (error) {
      toastMessage({
        type: 'error',
        title: 'Ocorreu um erro, contate o administrador'
      });
    }
  };

  return {
    getReportDetails,
    getPenailtiesCandidate,
    penalties,
    reportData,
    sendValidationReport,
    isLoading, setIsLoading,
    isLoadingPenalties
  };
};

export default useReviewCNH;
