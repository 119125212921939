import { TextareaAutosizeProps } from "@mui/material";
import { TextAreaStyled } from "./styles";

interface TextAreaProps extends TextareaAutosizeProps {

}

export function TextArea({...rest}: TextAreaProps){
    return(
        <TextAreaStyled {...rest}/>
    )
}