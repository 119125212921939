import React from 'react'
import { createContext, useContext, useEffect, useState } from "react";

type activeTabStatus =  'PROCESSANDO' | 'EM ANALISE' | 'CONCLUIDO' | 'ALL'

type ValidationCnhContextType = {
    activeTab: activeTabStatus,
    handleChangeActiveTab: (tab: activeTabStatus) => void,
    filterValues: filterFormProps,
    setFiltersValues: any,
    expandedAcordeon: boolean,
    handleToggleAcordeon: ()=> void
}
export interface filterFormProps {
  jobName?: any,
  jobCode?: any,
  recruiter?: any,
  jobCategory?: any
  candidateName?: string,
  candidateCpf?: string,
  state?: any,
  candidateCategory?: any,
  nadaconsta?: boolean,
  page?: number,
  createdValidationStart?: string,
  createdValidationEnd?: string,
  jobClosed?: boolean,
  notFinishStep?: boolean
}


export const ValidationCnhContext = createContext({} as ValidationCnhContextType);

export function ValidationCnhContextProvider({ children }: any) {
    const [activeTab, setActiveTab] =  useState<activeTabStatus>('CONCLUIDO')
    const [expandedAcordeon, setExpandedAcordeon] = useState(false)
    const [filterValues, setFiltersValues] = useState<filterFormProps>({
      nadaconsta: false,
      notFinishStep: false,
      jobClosed: false
  })
  
    
    const handleChangeActiveTab = (status: activeTabStatus) =>{
        setActiveTab(status)
    }

    const handleToggleAcordeon = ():void=>{
      setExpandedAcordeon(!expandedAcordeon)
    }
 
  return (
    <ValidationCnhContext.Provider
      value={{
        activeTab,
        handleChangeActiveTab,
        filterValues,
        setFiltersValues,
        expandedAcordeon, 
        handleToggleAcordeon
      }}
    >
      {children}
      
    </ValidationCnhContext.Provider>
  );
}

export function useValidationCnhContext() {
  return useContext(ValidationCnhContext);
}
