import { toastMessage } from "src/components/ToastMessege"
import api from "src/services/api"

type ResetPasswordProps = {
    token: string,
    email: string,
    userId: string,
    password: string,
}

export function useResetPasswoerd() {

    async function resetPassword(params: ResetPasswordProps) {
        try {
            const {data} = await api.post('/gupy/test/reset-password',{ ...params })

            toastMessage({
                type: 'success',
                title: 'Senha alterada, realize login!'
            }) 

        } catch (error) {
           toastMessage({
               type: 'error',
               title: 'Ocorreu um erro ao alterar a senha'
           }) 
        }   
    }

    return {
        resetPassword
    }
}