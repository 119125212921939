import styled from "@emotion/styled";
import { TextareaAutosize } from "@mui/material";


export const TextAreaStyled = styled(TextareaAutosize)`
    max-width: 100%;
    width: 100%;
    min-height: 70px;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
`