import { Button, Card, CardHeader, List, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { TextArea } from 'src/components/TextArea';
import { WhatsappOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

interface ModalRequestNadaConstaProps {
  phone: string | null;
  candidate: string;
  showModal: boolean;
  onToggle: () => void;
  idReport: string;
  recruiter: string;
  jobName: string;
  contextModalMessage: 'NADA_CONSTA' | 'RENOVATION_CERTIFICATE'
}

export function ModalRequestNadaConsta({
  phone,
  showModal,
  onToggle,
  candidate,
  idReport,
  recruiter,
  jobName,
  contextModalMessage
}: ModalRequestNadaConstaProps) {
  const [whatsapp, setWhatsapp] = useState<string>('')
  const [messageNadaConsta, setMessageNadaConsta] = useState<string>('')

  const linkNadaConsta = `https://ambev.vlow.com.br/cnh-validation/${idReport}/upload-nada-consta`
  // const messageNadaConsta = `Olá ${candidate}, para continuar o candidatura é necessario anexar o nada consta de sua CNH\n\nsegue link para anexar: ${linkNadaConsta}`

 const INIT_MESSAGE = {
  NADA_CONSTA: `Olá, *${candidate}*! Tudo bem? 
  Aqui é o(a) ${recruiter}, faço parte do Time de Recrutamento da Ambev!
  Entrando em contato porque para a vaga ${jobName} que você se candidatou precisamos validar algumas informações da sua CNH para garantirmos que esteja tudo certo!
  Como já temos a sua CNH, poderia inserir nesse link abaixo o seu histórico de pontuação de multas (Nada Consta) para checarmos, por favor?
      
  Segue link para anexar: https://ambev.vlow.com.br/cnh-validation/${idReport}/upload-nada-consta`,

  RENOVATION_CERTIFICATE: `Que boa notícia, a sua CNH teve o envio aprovado! Durante o período de validação dos critérios, observamos que sua CNH esta com status vencida. 
Caso já tenha realizado a renovação, poderá anexar o comprovante nesta segunda etapa:

Segue link para anexar: https://ambev.vlow.com.br/cnh-validation/${idReport}/upload-renovacao-cnh
  
Para casos de renovação não iniciada, aguarde o contato do time de Recrutamento da Ambev.`
 }

 const TITLE_MODAL = {
  NADA_CONSTA: 'Solicitar nada consta',
  RENOVATION_CERTIFICATE: 'Solicitar comprovante de renovação da CNH'
 }

  useEffect(() => {
    setWhatsapp(phone)
    setMessageNadaConsta(INIT_MESSAGE[contextModalMessage])
  }, [phone, contextModalMessage])

  const handleSendMessageNadaConsta = () => {
    const text = encodeURIComponent(messageNadaConsta)
    const url = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${text}`
    window.open(url)
  }

  return (
    <Modal
      open={showModal}
      onClose={onToggle}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >

      <Card
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 450,
          width: '100%',
          maxHeight: '80vh',
          m: 3,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          overflowY: 'auto',
          overflowX: 'none'
        }}
      >
        <Box sx={{
          position: 'sticky',
          top: 0,
          // right: 10,
          display: 'flex',
          justifyContent: 'end',
          padding: 2,
          marginBottom: -5,
        }}>
          <CloseIcon
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => onToggle()}
          />
        </Box>

        <CardHeader
          title={
            <>
              <Typography variant="h4"
                sx={{
                  marginLeft: 4
                }}
              >{TITLE_MODAL[contextModalMessage]}</Typography>

            </>
          }
        />
        <hr />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: 3
          }}
        >
          <TextField
            onChange={(e) => setWhatsapp(e.target.value)}
            sx={{
              width: '100%',
              margin: '15px 0'
            }}
            label="Whatsapp"
            value={whatsapp}
            focused
            placeholder='Digite o número do com o DDI ex: 55119....'
          />

          <TextArea onChange={(e) => setMessageNadaConsta(e.target.value)} value={messageNadaConsta} />
          <Button
            sx={{
              marginTop: '20px'
            }}
            variant='contained' color='success'
            onClick={() => handleSendMessageNadaConsta()}
          >
            <WhatsappOutlined />
            Enviar via WhatsApp</Button>
        </Box>

      </Card>
    </Modal>
  );
}
