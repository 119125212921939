import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {useAuthContext } from './contexts/AuthContext';
import LoginPage from './content/pages/Login';
import CandidateRivew from './content/Validation/CandidateReview';
import ResetPasswordPage from './content/pages/ResetPassword';
import ForgotPasswordPage from './content/pages/ForgotPassword';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const ListValidation = Loader(
  lazy(() => import('src/content/Validation/List'))
)

const Dashboard = Loader(
  lazy(()=> import('src/content/Dashboard/index'))
)

const Users = Loader(lazy(() => import('./content/Users/index')))

// const isAuthenticated = false

interface PrivateRouteProps {
  element: JSX.Element,
}

const PrivateRoute = ({element}: PrivateRouteProps)=>{
  const {isAuthenticated} = useAuthContext()
  console.log('isAuthenticated', isAuthenticated)

  return !isAuthenticated ? <Navigate to='/login'/> : element
  // return isAuthenticated ? element : <Navigate to='/login'/>
}

const RedirectIsLogged = ({element}: PrivateRouteProps) => {
  const {isAuthenticated} = useAuthContext()

  return !isAuthenticated ? element : <Navigate to='/validation'/>
}

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to='login'/>
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/login',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <RedirectIsLogged element={<LoginPage/>}/> 
      },
    ]
  },
  {
    path: '/reset-password/:token',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <RedirectIsLogged element={<ResetPasswordPage/>}/> 
      },
    ]
  },
  {
    path: '/forgot-password',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <RedirectIsLogged element={<ForgotPasswordPage/>}/> 
      },
    ]
  },
  {
    path: '/validation',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <PrivateRoute element={<ListValidation/>}/>
      },
      {
        path: ':validationId',
        element: <PrivateRoute element={<CandidateRivew/>}/>
      },
    ]
  },
  {
    path: '/dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <PrivateRoute element={<Dashboard/>}/>
      },
    ]
  },
  {
    path: '/users',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <PrivateRoute element={<Users/>}/>
      },
    ]
  },
  
  // {
  //   path: 'validation',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <ListValidation/>
  //     },
  //   ]
  // },
];

export default routes;
