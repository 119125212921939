import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  List,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { TextArea } from 'src/components/TextArea';
import { WhatsappOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { AiFillCopy, AiOutlineLink } from 'react-icons/ai';
import { toastMessage } from 'src/components/ToastMessege';
import api from 'src/services/api';

interface ModalRequestProps {
  phone: string | null;
  candidate: string;
  showModal: boolean;
  onToggle: () => void;
  idValidation: string;
  recruiter: string;
  jobName: string;
}

export function ModalGenerateLinkToFinishProcess({
  phone,
  showModal,
  onToggle,
  candidate,
  idValidation,
  recruiter,
  jobName
}: ModalRequestProps) {
  const [whatsapp, setWhatsapp] = useState<string>('');
  const [messege, setMessege] = useState<string>('');
  const [showFormSendMessege, setShowFormSendMessege] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const link = `https://ambev.vlow.com.br/cnh-validation/${idValidation}`;

  const INIT_MESSEGE = `Olá, *${candidate}*! Tudo bem? 
Aqui é o(a) ${recruiter}, faço parte do Time de Recrutamento da Ambev!
Entrando em contato porque para a vaga ${jobName} que você iniciou a candidatura e não finalizou, gostaria de finalizar o processo?
    
Segue link: ${link}`;

  useEffect(() => {
    setWhatsapp(phone);
    setMessege(INIT_MESSEGE);
  }, [phone]);

  const handleSendMessege = () => {
    const text = encodeURIComponent(messege);
    const url = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${text}`;
    window.open(url);
  };

  const handleResetValidationCandidate = async () => {
    setIsLoading(true);
    await api.post('/gupy/test/cnh-validation/reset-validation', {
      validationId: idValidation
    });
    setShowFormSendMessege(true);
    setIsLoading(false);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(link);
    toastMessage({
      type: 'success',
      title: 'link copiado com sucesso!'
    });
  };

  return (
    <Modal
      open={showModal}
      onClose={onToggle}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 450,
          width: '100%',
          maxHeight: '80vh',
          m: 3,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          overflowY: 'auto',
          overflowX: 'none'
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            display: 'flex',
            justifyContent: 'end',
            padding: 2,
            marginBottom: -5
          }}
        >
          <CloseIcon
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => onToggle()}
          />
        </Box>

        <CardHeader
          title={
            <>
              <Typography
                variant="h4"
                sx={{
                  marginLeft: 4
                }}
              >
                Reiniciar processo de {candidate}
              </Typography>
            </>
          }
        />
        <hr />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: 3
          }}
        >
          {!showFormSendMessege && (
            <>
              <Typography>
                Ao continuar com a reinicialização do processo, as validações já
                realizada serão perdidas.
              </Typography>
              <Button
                sx={{
                  marginTop: '10px'
                }}
                variant="outlined"
                color="primary"
                disabled={isLoading}
                onClick={() => handleResetValidationCandidate()}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <AiOutlineLink size={20} />
                    Reiniciar processo
                  </>
                )}
              </Button>
            </>
          )}

          {showFormSendMessege && (
            <>
            <Box sx={{background: '#bcbcbc', cursor: 'pointer', display: 'flex', alignItems: 'center', p:1, borderRadius:1}}>
            <Typography sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={copyToClipBoard}>{link}</Typography>
            <AiFillCopy color='blue' size={30}/>
            </Box>

              <TextField
                onChange={(e) => setWhatsapp(e.target.value)}
                sx={{
                  width: '100%',
                  margin: '15px 0'
                }}
                label="Whatsapp"
                value={whatsapp}
                focused
                placeholder="Digite o número do com o DDI ex: 55119...."
              />

              <TextArea
                onChange={(e) => setMessege(e.target.value)}
                value={messege}
              />
              <Button
                sx={{
                  marginTop: '20px'
                }}
                variant="contained"
                color="success"
                onClick={() => handleSendMessege()}
              >
                <WhatsappOutlined />
                Enviar via WhatsApp
              </Button>
            </>
          )}
        </Box>
      </Card>
    </Modal>
  );
}
