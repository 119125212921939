import styled from "@emotion/styled";
import { Box, ButtonBase, TextField } from "@mui/material";

export const Container = styled(Box)`
  overflow: auto;
  display: flex;
  flex: 1;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
`
export const Form = styled(Box)`
    display: flex;
    overflow: auto;
    flex: 1;
    max-width: 400px;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 25vh;
    padding: 15px;
`

export const Button = styled(ButtonBase)`
    width: 100%;
    background: #1D4ED8;
    margin: 10px 0;
    font-size: 1.2rem;
    color: white;
    height: 35px;
`
export const Input = styled(TextField)`
    margin: 10px 0;
    width: 100%;
    background: white;
    border-radius: 10px;
`

export const Img = styled.img`
    height: 60px;
    margin: 15px 0;
`