import {
  AlertTitle,
  Button as ButtonMui,
  CardHeader,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as LinkRRD } from 'react-router-dom';
import { AiOutlineLink } from 'react-icons/ai';
import { BsCheckCircleFill } from 'react-icons/bs';
import { RiCloseCircleFill, RiErrorWarningFill } from 'react-icons/ri';
import { IoMdRemoveCircle } from 'react-icons/io';

import {
  Container,
  Card,
  Alert,
  Button,
  Ul,
  Link,
  BodyLi,
  ImgLogo,
  ImgDoc
} from './styles';
import useReviewCNH from './useCandidateReview';
import { useEffect, useState } from 'react';
import { contexModalEnum, ModalValidate } from './ModalValidate';
import { cpfMask } from 'src/helpers/utils/cpfMask';
import { ModalViewPenalties } from './ModalViewPenalties';
import { ModalRequestNadaConsta } from './ModalRequestNadaConsta';
import { STATES_TO_NADACONSTA } from 'src/helpers/constants';
import { useValidationCnhContext } from 'src/contexts/validation/ValidationContext';
import Label from 'src/components/Label';
import { ListCheckSlkeleton } from './ListCheksSkeleon';
import moment from 'moment';
import { ModalGenerateLinkToFinishProcess } from './ModalGenerateLinkToFinishProcess';
import { useAuthContext } from 'src/contexts/AuthContext';

type ContextModalRequestDataType =
  | 'NADA_CONSTA'
  | 'RENOVATION_CERTIFICATE'
  | null;

export default function CandidateRivew() {
  const { isReaderUser, user } = useAuthContext();

  const [contextModal, setContextModal] = useState<contexModalEnum>(
    contexModalEnum.aprove
  );
  const [showModal, setShowModal] = useState(false);
  const [showModalPenalties, setShowModalPenalTies] = useState(false);
  const [showModalRequestNadaConsta, setShowModalRequestNadaConsta] =
    useState(false);

  const [contextModalRequestData, setContextModalRequestData] =
    useState<ContextModalRequestDataType>();
  const [
    showModalGenerateLinkToFinishProcess,
    setShowModalGenerateLinkToFinishProcess
  ] = useState(false);

  const { validationId } = useParams();
  const {
    getReportDetails,
    reportData,
    isLoading,
    getPenailtiesCandidate,
    penalties,
    isLoadingPenalties
  } = useReviewCNH();

  const init = () => {
    getReportDetails(validationId);
  };

  type ProcessStatus =
    | 'EM ANALISE'
    | 'CONCLUIDO'
    | 'PRE-PROCESSANDO'
    | 'PROCESSANDO';
  const getStatusProcessLabel = (status: string): JSX.Element => {
    const map = {
      'PRE-PROCESSANDO': {
        text: 'PRE-PROCESSANDO',
        color: 'info'
      },
      PROCESSANDO: {
        text: 'PROCESSANDO',
        color: 'info'
      },
      'EM ANALISE': {
        text: 'ANALISE',
        color: 'warning'
      },
      CONCLUIDO: {
        text: 'CONCLUIDO',
        color: 'success'
      }
    };
    const { text, color }: any = map[status];

    return <Label color={color}>{text}</Label>;
  };

  type ValidataionStatus = 'EM ANALISE' | 'APROVADO' | 'REPROVADO';

  const getStatusLabel = (status: ValidataionStatus): JSX.Element => {
    const map = {
      REPROVADO: {
        text: 'REPROVADO',
        color: 'error'
      },
      APROVADO: {
        text: 'APROVADO',
        color: 'success'
      },
      'EM ANALISE': {
        text: 'ANALISE',
        color: 'warning'
      }
    };

    const { text, color }: any = map[status];

    return <Label color={color}>{text}</Label>;
  };

  const checkValidationReport = (arrayOfBoolean: boolean[]) => {
    const isReproved = arrayOfBoolean.some((item) => item === false);

    if (isReproved) {
      return true;
    } else {
      return false;
    }
  };
  const isReproved =
    reportData?.cnhValidationData?.isApproved !== null
      ? !reportData?.cnhValidationData?.isApproved
      : checkValidationReport([
          reportData?.cnhValidationData?.is_definitive,
          reportData?.cnhValidationData?.less_than_eight_points,
          reportData?.cnhValidationData?.not_expired,
          reportData?.cnhValidationData?.no_serious_penalty,
          reportData?.cnhValidationData?.isCorrectCategory
        ]);

  const colorCheck = (status: boolean | null) => {
    switch (status) {
      case true:
        return '#008000';
      case false:
        return '#FF0000';
      default:
        return '#FCBE11';
    }
  };

  const iconsCheck = (status: boolean | null) => {
    switch (status) {
      case true:
        return (
          <>
            <Tooltip arrow title="Aprovado">
              <BsCheckCircleFill size={21} color="#22C55E" />
            </Tooltip>
          </>
        );

      case false:
        return (
          <>
            <Tooltip arrow title="Reprovado">
              <RiCloseCircleFill size={26} color="#EF4444" />
            </Tooltip>
          </>
        );
      default:
        return (
          <>
            <Tooltip arrow title="Necessário revisar">
              <RiErrorWarningFill size={27} color="#FCBE11" />
            </Tooltip>
          </>
        );
    }
  };

  const handleToggleModal = (): void => {
    setShowModal(!showModal);
  };

  const handleToggleModalPenalties = (): void => {
    setShowModalPenalTies(!showModalPenalties);
  };
  const handleToggleModalRequestNadaConsta = (
    contextModalRequest?: ContextModalRequestDataType
  ): void => {
    if (contextModalRequest) {
      setContextModalRequestData(contextModalRequest);
    }
    setShowModalRequestNadaConsta(!showModalRequestNadaConsta);
  };

  const handleToggleModalGenerateLinkFinishProcess = (): void => {
    setShowModalGenerateLinkToFinishProcess(
      !showModalGenerateLinkToFinishProcess
    );
  };

  const _getTextButtonPenalties = () => {

    console.log(reportData?.cnhValidationData?.pontuation)

    if (reportData?.cnhValidationData?.pontuation?.length) {
      return 'Histórico de multas'

    }

    if (isLoadingPenalties) {
      return 'Buscando penalidades';
    }

    if (!reportData?.cnhValidationData?.reportid) {
      return 'CNH não checada via OCR';
    }

    if (!penalties?.length) {
      return 'Não foram encontrados penalidades';
    }

    if (penalties?.length) {
      return 'Histórico de multas';
    }
  };

  const isDisableModalPenalties = () => {
    console.log('has pontos', reportData?.cnhValidationData?.pontuation?.length)

    if (isLoadingPenalties) {
      console.log('carregando')
      return true;
    }

    if (!reportData?.cnhValidationData?.pontuation?.length && !penalties?.length) {
      console.log('sem penalidades')
      return true;
    }

    return false;
  }

  useEffect(() => {
    init();
  }, [validationId]);

  useEffect(() => {
    if (reportData?.cnhValidationData?.reportid) {
      getPenailtiesCandidate(reportData?.cnhValidationData?.reportid);
    }
  }, [reportData?.cnhValidationData?.reportid]);

  return (
    <>
      <Container>
        <LinkRRD
          to="/validation"
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#061C39'
          }}
        >
          <ArrowBackIcon /> Voltar
        </LinkRRD>
        <Card>
          <CardHeader
            title={
              <>
                {isLoading ? (
                  <Skeleton variant="text" width={'80%'} />
                ) : (
                  <>
                    {getStatusProcessLabel(
                      reportData?.cnhValidationData?.status || 'EM ANALISE'
                    )}
                    {reportData?.cnhValidationData?.status === 'CONCLUIDO' &&
                      getStatusLabel(isReproved ? 'REPROVADO' : 'APROVADO')}
                    <Typography
                      variant="subtitle1"
                      fontSize={24}
                      fontWeight="bold"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      Vaga:{' '}
                      {
                        reportData?.cnhValidationData?.gupy_test_candidate
                          ?.jobName
                      }
                      {reportData?.cnhValidationData?.application_id && (
                        <a
                          href={`https://ambev.gupy.io/companies/jobs/${reportData?.cnhValidationData?.gupy_test_candidate.job_id}/candidates/${reportData?.cnhValidationData?.application_id}?type=all`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ImgLogo src="/static/svg/gupy-logo.svg" />
                        </a>
                      )}
                    </Typography>
                  </>
                )}

                <Typography
                  variant="subtitle1"
                  sx={{
                    display: 'flex'
                  }}
                >
                  <b>Codigo</b>:{' '}
                  {isLoading ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    reportData?.cnhValidationData?.gupy_test_candidate?.jobCode
                  )}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex' }}>
                  <b>Recrutador</b>:
                  {isLoading ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    reportData?.cnhValidationData?.gupy_test_candidate
                      ?.recruiter
                  )}
                </Typography>
                {reportData?.cnhValidationData?.status === 'CONCLUIDO' && (
                  <Typography variant="subtitle1" sx={{ display: 'flex' }}>
                    <b>Validado</b>:{' '}
                    {isLoading ? (
                      <Skeleton variant="text" width={100} />
                    ) : reportData?.cnhValidationData?.isAutomatic === true ? (
                      <Label color="info">Automaticamente</Label>
                    ) : (
                      <Label fitContent color="warning">
                        Manualmente por{' '}
                        {reportData?.cnhValidationData?.user?.name}
                      </Label>
                    )}
                  </Typography>
                )}
                <Typography variant="subtitle1" sx={{ display: 'flex' }}>
                  <b>Data de cadastro</b>:
                  {isLoading ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    moment(reportData?.cnhValidationData?.createdAt).format(
                      'DD/MM/YYYY'
                    )
                  )}
                </Typography>
              </>
            }
          />

          {reportData?.cnhValidationData?.invalidFirstStepDescription && (
            <Alert severity="error" sx={{ marginBottom: 1 }}>
              <AlertTitle>Observação </AlertTitle>
              <Typography>
                {' '}
                {reportData?.cnhValidationData?.invalidFirstStepDescription}
              </Typography>
            </Alert>
          )}

          {reportData?.cnhValidationData?.status === 'CONCLUIDO' &&
            reportData?.cnhValidationData?.disaprovalReason && (
              <Alert severity="error">
                <AlertTitle>Reprovado </AlertTitle>
                <Typography>
                  {' '}
                  {reportData?.cnhValidationData?.disaprovalReason}
                </Typography>
              </Alert>
            )}

          {['EM ANALISE', 'PROCESSANDO'].includes(
            reportData?.cnhValidationData?.status
          ) && (
            <>
              <Alert severity="warning">
                <AlertTitle>Revisão necessária </AlertTitle>A checagem
                automatica encontrou alguns pontos que precisam de revisão
                manual. Analise e <strong>aprove/reprove</strong> abaixo
              </Alert>

              {(!isLoading && !isReaderUser()) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end'
                  }}
                >
                  <Button
                    context="reprove"
                    onClick={() => {
                      handleToggleModal();
                      setContextModal(contexModalEnum.reprove);
                    }}
                  >
                    Reprovar
                  </Button>
                  <Button
                    context="approve"
                    onClick={() => {
                      handleToggleModal();
                      setContextModal(contexModalEnum.aprove);
                    }}
                  >
                    Aprovar
                  </Button>
                </Box>
              )}
            </>
          )}
        </Card>

        <Card>
          <CardHeader
            title={
              <>
                <Typography variant="subtitle1" fontSize={24} fontWeight="bold">
                  {isLoading ? (
                    <Skeleton variant="text" width={450} />
                  ) : (
                    reportData?.cnhValidationData?.gupy_test_candidate?.name
                  )}
                  {(reportData?.cnhValidationData?.status !== 'CONCLUIDO' || user?.role === 'SUPER_ADMIN') && (
                    <ButtonMui
                      onClick={handleToggleModalGenerateLinkFinishProcess}
                    >
                      <AiOutlineLink size={20} /> Reiniciar processo
                    </ButtonMui>
                  )}
                </Typography>

                {isLoading ? (
                  <Skeleton variant="text" width={250} />
                ) : (
                  <Typography variant="subtitle1">
                    CNH n°: {reportData?.cnhValidationData?.cnhnumber} | Emitida
                    em {reportData?.cnhValidationData?.uf}
                  </Typography>
                )}
                {isLoading ? (
                  <Skeleton variant="text" width={250} />
                ) : (
                  <Typography variant="subtitle1">
                    CPF: {cpfMask(reportData?.cnhValidationData?.cpf)}
                  </Typography>
                )}
              </>
            }
          />
          <hr />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                width: '50%'
              }}
            >
              {isLoading ? (
                <ListCheckSlkeleton />
              ) : (
                <Ul>
                  <li>
                    {iconsCheck(reportData?.cnhValidationData?.not_expired)}
                    <BodyLi>
                      <span>Não expirada</span>
                      <div>
                        <Typography
                          fontWeight="bold"
                          color={colorCheck(
                            reportData?.cnhValidationData?.not_expired
                          )}
                        >
                          Válido ate {reportData?.cnhValidationData?.validdate}
                        </Typography>
                        {!reportData.cnhValidationData.not_expired &&
                          !isReaderUser() && (
                            <ButtonMui
                              variant="outlined"
                              color="error"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                cursor: 'pointer'
                                // maxWidth: 127
                              }}
                              onClick={() =>
                                handleToggleModalRequestNadaConsta(
                                  'RENOVATION_CERTIFICATE'
                                )
                              }
                            >
                              Solicitar comprovante de renovação
                              <SendOutlinedIcon sx={{ marginLeft: 1 }} />
                            </ButtonMui>
                          )}
                      </div>
                    </BodyLi>
                  </li>
                  <li>
                    {iconsCheck(reportData?.cnhValidationData?.is_definitive)}
                    <BodyLi>
                      <span>CNH definitiva</span>
                      <Typography
                        fontWeight="bold"
                        color={colorCheck(
                          reportData?.cnhValidationData?.is_definitive
                        )}
                      >
                        {' '}
                        Primeira habilitação:{' '}
                        {reportData?.cnhValidationData?.firstqualificationdate}
                      </Typography>
                    </BodyLi>
                  </li>
                  <li>
                    {iconsCheck(
                      reportData?.cnhValidationData?.isCorrectCategory
                    )}
                    <BodyLi>
                      <span>Categoria compatível com vaga</span>
                      <Typography
                        fontWeight="bold"
                        color={colorCheck(
                          reportData?.cnhValidationData?.isCorrectCategory
                        )}
                      >
                        {' '}
                        Candidato: {reportData?.cnhValidationData?.category} |
                        Vaga:{' '}
                        {
                          reportData?.cnhValidationData?.gupy_test_candidate
                            ?.gupy_test?.category
                        }
                      </Typography>
                    </BodyLi>
                  </li>
                  <li>
                    {iconsCheck(
                      reportData?.cnhValidationData?.no_serious_penalty
                    )}
                    <BodyLi>
                      <span>Sem multa gravíssima (últimos 12 meses)</span>
                    </BodyLi>
                  </li>
                  <li>
                    {iconsCheck(
                      reportData?.cnhValidationData?.less_than_eight_points
                    )}
                    <BodyLi>
                      <span>Menor ou igual a 8 pontos (últimos 12 meses)</span>
                    </BodyLi>
                  </li>
                  <li>
                    <ButtonMui
                      onClick={() => handleToggleModalPenalties()}
                      sx={{
                        marginLeft: 1
                      }}
                      variant="outlined"
                      color="warning"
                      disabled={isDisableModalPenalties()}
                    >
                      {_getTextButtonPenalties()}
                      <VisibilityOutlinedIcon
                        sx={{
                          marginLeft: 1
                        }}
                      />
                    </ButtonMui>
                  </li>
                </Ul>
              )}
            </Box>

            <Box
              sx={{
                width: '50%'
              }}
            >
              <Ul>
                {!reportData?.cnhValidationData?.cnh_digital ? (
                  <>
                    <li>
                      <Link
                        href={reportData?.cnhValidationData?.cnh_front}
                        target="_blank"
                      >
                        <ImgDoc src="/static/svg/cnh-exemple-front.svg" />
                        <Typography variant="subtitle1" fontSize={20}>
                          Frente
                        </Typography>
                      </Link>
                      <Link
                        href={reportData?.cnhValidationData?.cnh_back}
                        target="_blank"
                      >
                        <ImgDoc src="/static/svg/cnh-exemple-back.svg" />
                        <Typography variant="subtitle1" fontSize={20}>
                          Verso
                        </Typography>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        href={reportData?.cnhValidationData?.cnh_digital}
                        target="_blank"
                      >
                        <ImgDoc src="/static/images/cnh-digital-exemple.png" />
                        <Typography variant="subtitle1" fontSize={20}>
                          CNH Digital
                        </Typography>
                      </Link>
                    </li>
                  </>
                )}

                <li>
                  <Box>
                    <Link
                      href={reportData?.cnhValidationData?.nadaconsta}
                      target="_blank"
                      border={!!reportData?.cnhValidationData?.nadaconsta}
                    >
                      <ImgDoc src="/static/images/nada-consta-icon.png" />
                      <Typography variant="subtitle1" fontSize={20}>
                        Nada Consta
                      </Typography>
                    </Link>

                    {!isReaderUser() && (
                      <ButtonMui
                        variant="contained"
                        color="warning"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          cursor: 'pointer',
                          maxWidth: 127
                        }}
                        onClick={() =>
                          handleToggleModalRequestNadaConsta('NADA_CONSTA')
                        }
                      >
                        {reportData?.cnhValidationData?.nadaconsta
                          ? `Solicitar \n novamente`
                          : 'Solicitar'}
                        <SendOutlinedIcon sx={{ marginLeft: 1 }} />
                      </ButtonMui>
                    )}
                  </Box>
                </li>
              </Ul>
            </Box>
          </Box>
        </Card>
      </Container>

      {/* Modals */}
      <ModalValidate
        validationId={validationId || ''}
        showModal={showModal}
        contextModal={contextModal}
        onToggle={handleToggleModal}
        namePerson={reportData?.cnhValidationData?.gupy_test_candidate?.name}
        documentPerson={cpfMask(reportData?.cnhValidationData?.cpf)}
        reportid={reportData?.cnhValidationData?.reportid}
        checksToValidate={{
          less_than_eight_points:
            reportData?.cnhValidationData?.less_than_eight_points,
          no_serious_penalty: reportData?.cnhValidationData?.no_serious_penalty,
          not_expired: reportData?.cnhValidationData?.not_expired,
          is_definitive: reportData?.cnhValidationData?.is_definitive,
          isCorrectCategory: reportData?.cnhValidationData?.isCorrectCategory
        }}
      />

      <ModalViewPenalties
        showModal={showModalPenalties}
        penalties={penalties?.length ? penalties : reportData?.cnhValidationData?.pontuation}
        candidate={reportData?.cnhValidationData?.gupy_test_candidate?.name}
        onToggle={handleToggleModalPenalties}
      />

      <ModalRequestNadaConsta
        showModal={showModalRequestNadaConsta}
        candidate={reportData?.cnhValidationData?.gupy_test_candidate?.name}
        phone={reportData?.cnhValidationData?.gupy_test_candidate?.phone}
        onToggle={handleToggleModalRequestNadaConsta}
        idReport={reportData?.cnhValidationData?.id}
        recruiter={
          reportData?.cnhValidationData?.gupy_test_candidate?.recruiter
        }
        jobName={reportData?.cnhValidationData?.gupy_test_candidate?.jobName}
        contextModalMessage={contextModalRequestData}
      />

      <ModalGenerateLinkToFinishProcess
        showModal={showModalGenerateLinkToFinishProcess}
        candidate={reportData?.cnhValidationData?.gupy_test_candidate?.name}
        phone={reportData?.cnhValidationData?.gupy_test_candidate?.phone}
        onToggle={handleToggleModalGenerateLinkFinishProcess}
        idValidation={reportData?.cnhValidationData?.id}
        recruiter={
          reportData?.cnhValidationData?.gupy_test_candidate?.recruiter
        }
        jobName={reportData?.cnhValidationData?.gupy_test_candidate?.jobName}
      />
    </>
  );
}
