import { Skeleton, Typography } from '@mui/material';
import { chunk } from 'lodash';
import { BodyLi, Ul } from '../styles';

export function ListCheckSlkeleton() {
    const lines = chunk(new Array(5))
  return (
    <Ul>
      {
        lines.map((_, index)=>(
            <li key={index}>
        <BodyLi>
          <span><Skeleton variant="text" width={150} /></span>
          <Typography>
            <Skeleton variant="text" width={200} />
          </Typography>
        </BodyLi>
      </li>
        ))
      }
    </Ul>
  );
}
