
import api from './api';

type SignInRequestData = {
  email: string;
  password: string;
}

type TokenData = {
  user:{
    id: string;
  name: string;
  email: string;
  role: string;
  picture: string
  }
}

export async function signInRequest(dataRquest: SignInRequestData) {
  // await delay()

  const { data } = await api.post('/login', {
    ...dataRquest
  })

  return {
    token: data.token,
    user: {...data.user}
  }
}

export async function recoverUserInformation(token: string) {
  try {
    const {data} = await api.post('/user/validate/token',{token})
    const {user}:TokenData = data.payload
    if(!user){
      return null
    }

    return user
  } catch (error) {
    return null
  }  
}

