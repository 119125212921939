import { Card, CardHeader, List, Modal, Typography } from '@mui/material';
import { Ul } from './styles';
import { PenaltyProps } from '../useCandidateReview';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { map, orderBy } from 'lodash';

interface MOdalViewPenaltiesProps {
  penalties: PenaltyProps[];
  candidate: string;
  showModal: boolean;
  onToggle: ()=> void
}

const _countPoints = (arrayOfPenalty: PenaltyProps[], breakPoint: number) => {
  return arrayOfPenalty?.reduce((acc, curr) => {
    if (Number(curr?.pontos || curr?.points) === breakPoint) {
      return acc + 1;
    } else {
      return acc + 0;
    }
  }, 0);
};

export function ModalViewPenalties({
  penalties,
  candidate,
  showModal,
  onToggle
}: MOdalViewPenaltiesProps) {
  const totalSlightPenalty = _countPoints(penalties, 3);
  const totalMeanousPenalty = _countPoints(penalties, 4);
  const totalSeriousPenalty = _countPoints(penalties, 5);
  const totalVerySeriousPenalty = _countPoints(penalties, 7);

  console.log(penalties)
  return (
    <Modal
      open={showModal}
        onClose={onToggle}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      
      <Card
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 700,
          width: '100%',
          maxHeight: '80vh',
          m: 3,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          overflowY: 'auto',
          overflowX: 'none'
        }}
      >
        <Box sx={{
            position: 'sticky',
            top: 0,
            // right: 10,
            display: 'flex',
            justifyContent: 'end',
            padding: 2,
            marginBottom: -5,
          }}>
        <CloseIcon
        onClick={()=>onToggle()}          
        />
        </Box>
        
        <CardHeader
          title={
            <>
              <Typography variant="h3"
              sx={{
                marginLeft: 4
              }}
              >{candidate}</Typography>
              
            </>
          }
        />

        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Typography variant='subtitle1' fontSize={19}>
            Leve: {totalSlightPenalty} | 
            Média: {totalMeanousPenalty} | 
            Grave: {totalSeriousPenalty} |
            Gravíssima: {totalVerySeriousPenalty}
            </Typography>
        </Box>
        <List>
        <Ul>
          {map(penalties ,(item, index) => (
            <li key={index}>
              <Typography variant="subtitle1">
                <b>Descrição:</b> {item?.descricao || item?.infraction}
              </Typography>
              <Typography variant="subtitle2">
                <b>Data:</b> {item?.emitido_em || item?.dateTime}
              </Typography>
              <Typography variant="subtitle2">
                <b>Local:</b> {item?.local || item?.place}
              </Typography>
              <Typography variant="subtitle2">Fronte: {item?.fonte || item?.organ}</Typography>
              <Typography variant="subtitle2" color="#c13535">
                <b>Pontos:</b> {item?.pontos || item?.points}
              </Typography>
            </li>
          ))}
        </Ul>
        </List>
      </Card>
    </Modal>
  );
}
