import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';

export const menuOptions = [
    {
      // subheader: 'Dashboards',
      items: [
      {
        name: 'Validação',
        to: '/validation',
        startIcon: <TableChartTwoToneIcon />
      },
      {
        name: 'Dashboard',
        to: '/dashboard',
        startIcon: <DashboardIcon />,
        disabled: false
      },
      {
        name: 'Usuarios',
        to: '/users',
        startIcon: <PersonIcon/>,
        role: ['DP_RH', 'RECRUITER', 'SUPER_ADMIN']
      }
    ]
    }
  ]