import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useAuthContext } from 'src/contexts/AuthContext';
import { useJwt } from "react-jwt";
import { Container, Button, Form, Input, Img } from './styles';
import { useResetPasswoerd } from './useResetPassword';

interface ResetPasswordProps {
  email: string;
  password: string;
  confirmPassword: string;
}
type property = 'email' | 'password' | 'confirmPassword';

type UseJWTProps = {
  decodedToken: {
    email: string,
    userId: string
  }

}

export default function ResetPasswordPage() {

  const {token} = useParams()
  const navigate = useNavigate()
  console.log(token)
  const {decodedToken}: UseJWTProps = useJwt(token)
  console.log(decodedToken)
  
  const [user, setUser] = useState<ResetPasswordProps>();
  const { signIn, isAuthenticated, isInvalidCrendentials } = useAuthContext();
  const {resetPassword} = useResetPasswoerd()

  const handleSigIn = async () => {
    await resetPassword({
      token,
      email: decodedToken?.email,
      userId: decodedToken?.userId,
      password: user?.password
    })

    navigate('/login')
  };

  const handleChange = (property: property, value: string) => {
    const data = {
      ...user,
      [property]: value
    };
    setUser(data);
  };

  useEffect(() => {
    setUser(prev => ({
      ...prev,
      email: decodedToken?.email
      }))
  }, [decodedToken])


  const disabledButton = user?.password !== user?.confirmPassword || !user?.password || !user?.confirmPassword

  return (
    <>
      <Helmet>
        <title>backoffice</title>
      </Helmet>

      <Container>
        <Form>
          
          <Img
            src="/static/images/logo/ambev-logo-s.png"
            alt="Logo"
          />
          {
            isInvalidCrendentials && (
              <Alert color='error'>Email ou senha inválidos</Alert>
            )
          }
          <Input
            label="E-mail"
            type="email"
            disabled
            value={user?.email}
          />
          <Input
            label="Senha"
            type="password"
            value={user?.password}
            onChange={(e) => handleChange('password', e.target.value)}
          />
           <Input
            label="Confirme a senha"
            type="password"
            value={user?.confirmPassword}
            onChange={(e) => handleChange('confirmPassword', e.target.value)}
          />
          {
            (user?.password !== user?.confirmPassword && user?.password && user?.confirmPassword) && (
              <Alert color='error'>Senhas não conferem</Alert>
            )
          }
          <Button disabled={disabledButton} onClick={handleSigIn}>Salvar</Button>
        </Form>
      </Container>
    </>
  );
}
