import styled from 'styled-components'

export const Ul = styled.ul`
list-style-type: none;


li {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    flex-direction: column;
}
`