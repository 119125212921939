import { toast } from 'react-toastify';

type ToastMessage = {
    title: string;
    type: 'success' | 'error';
};

export const toastMessage = ({ type, title }: ToastMessage): void => {
  toast[type](title, {
    toastId: type,
    autoClose: 3000,
    closeOnClick: true,
  });
};
